import { cn } from "@/lib/utils.ts";
import type { FormApi } from "@rvf/remix";
import { motion } from "framer-motion";
import type { ReactNode } from "react";
import React, { useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import { useControlField, useField } from "remix-validated-form";
import { TriggerAnimation } from "~zipdeal-ui/animations/TriggerAnimation.tsx";
import { useBrand } from "~zipdeal-ui/contexts-and-providers/BrandProvider.tsx";
import { ZD_ERRORS, ZD_INPUT } from "~zipdeal-ui/globalColors.ts";
import { CircleIcon } from "./CircleIcon.tsx";

interface PillProps {
	name: string;
	isSelected: boolean;
	value: string;
	afterChange?: Function;
	setValidatorValue: Function;
	description: ReactNode;
	appendixNode?: ReactNode;
	label: ReactNode;
	textColor?: string;
	backgroundColor?: string;
	isErrored?: boolean;
	trueFalse?: boolean;
	testId?: string;
	formId?: string;
	dataExtra?: string;
	labelSize?: string;
	radioButtonSize?: string;
	descriptionClassName?: string;
	labelClassname?: string;
	minHeight?: string;
	className?: string;
	onChange?: Function;
	onDoubleClick?: Function;
	onBlur?: ((...args: any[]) => void) | undefined;
	enborderDescription?: boolean;
	pillStateForGroup?: string;
}

export const RadioPill = ({
	name,
	isSelected,
	formId = undefined,
	value,
	description,
	setValidatorValue,
	label,
	afterChange = undefined,
	trueFalse = false,
	textColor = ZD_INPUT.TEXT,
	backgroundColor = ZD_INPUT.BACKGROUND,
	isErrored = false,
	testId = undefined,
	dataExtra = undefined,
	onChange = undefined,
	enborderDescription = false,
	appendixNode = <div />,
	labelSize = "text-base pl-2 pr-2 pt-2.5 pb-2.5",
	radioButtonSize = "h-6 w-6",
	minHeight = "min-h-10",
	onDoubleClick = undefined,
	className = "",
	labelClassname = "",
	descriptionClassName = "",
	pillStateForGroup = undefined,
	onBlur = undefined,
}: PillProps) => {
	const { error, getInputProps } = useField(name, {
		formId: formId || "undetermined-form",
	});
	const [_value, _setValue] = useControlField(name, formId);

	const pillRef = useRef(null);
	const dataTestId = testId || `${name}:${value}`;
	const { brand } = useBrand();
	const commonInputProps = getInputProps({
		id: `${name}:${value}`,
		"data-testid": name,
		value,

		onBlur: onBlur,
		onChange: onChange
			? (event) => {
					const { value } = event.target;
					let convertedValue = value;
					if (trueFalse) {
						convertedValue = value === "true";
					}
					onChange({
						event,
						name,
						formId,
						normalizedValue: convertedValue,
						_setValue,
						setValidatorValue,
						afterChange,
					});
				}
			: (event) => {
					const { value } = event.target;
					let convertedValue = value;
					if (trueFalse) {
						convertedValue = value === "true";
					}
					_setValue(convertedValue);
					setValidatorValue(convertedValue);
					afterChange?.(convertedValue);
					return convertedValue;
				},
	} as any);

	return (
		<TriggerAnimation
			getVariant={({ width, defaultTriggeredVariant }) => {
				return {
					scale: [0.8, 0.8, 1.5, 1],
					transition: {
						...defaultTriggeredVariant.transition,
						duration: 0.4,
					},
				};
			}}
		>
			{({
				triggerAnimation: outerTriggerAnimation,
				animationProps: outerAnimationProps,
				ref: ref1,
			}) => (
				<TriggerAnimation
					getVariant={({ width, defaultTriggeredVariant }) => {
						return {
							scale: [1, 1.3, 0.8, 1],
							transition: {
								...defaultTriggeredVariant.transition,
								duration: 0.4,
								delay: 0.1,
							},
						};
					}}
				>
					{({
						triggerAnimation: dotTriggerAnimation,
						animationProps: dotAnimationProps,
						ref: ref2,
					}) => (
						<>
							<motion.div
								ref={mergeRefs([ref1, ref2])}
								data-value-id={dataTestId}
								className={cn({
									"group flex cursor-pointer items-center rounded border-2": true,
									"transition duration-300 ease-in-out": true,
									"w-full": false,
									[ZD_INPUT.BORDER]: true,
									[`border-accent-${brand}/75`]: isSelected,
									"flex-wrap": description,
									[`focus-within:outline-none focus-within:ring-2 focus-within:ring-brandDark-${brand} focus-within:border-transparent `]: true,
									"opacity-50": pillStateForGroup === "SELECTION__UNSELECTED",
									[ZD_ERRORS.BORDER]: isErrored,
									[className]: !!className,
								})}
								onDoubleClick={(event) => {
									let convertedValue: string | boolean = value;

									if (trueFalse) {
										convertedValue = value === "true";
									}
									onDoubleClick?.({
										value,
										name,
										formId,
										setValue: _setValue,
										normalizedValue: convertedValue,
									});
								}}
								data-testid={dataTestId}
								data-value={value}
								data-role={"radiobutton"}
								data-extra={dataExtra}
							>
								<motion.label
									className={cn({
										"group flex h-full w-full cursor-pointer": true,
										[`group-focus:outline-brand-${brand}`]: true,
									})}
								>
									<React.Fragment>
										<input
											className={cn({
												"form-radio pin-t pin-l pointer-events-none absolute border-0 text-base opacity-0": true, // Pointer-Events are not on b/c SelectedRadioButtonField has issues with clicking on the dot
												[ZD_ERRORS.BORDER]: isErrored,
												[`group-focus:outline-brand-${brand}`]: true,
											})}
											type={"radio"}
											key={value}
											form={formId}
											checked={isSelected}
											ref={pillRef}
											{...commonInputProps}
											onChange={(event) => {
												dotTriggerAnimation();
												outerTriggerAnimation();
												commonInputProps.onChange?.(event);
											}}
											value={value}
										/>
										<motion.em
											className={cn({
												"flex items-center justify-center rounded-l border-r bg-white px-2": true,
												[ZD_INPUT.BORDER]: !isSelected,
												[minHeight]: true,
												[`border-accent-${brand}/50`]: isSelected,
												[`group-focus:outline-brand-${brand}`]: true,
											})}
										>
											<CircleIcon
												className={cn({
													[radioButtonSize]: true,
													"text-white": !isSelected,
													[`text-accent-${brand}/75`]: isSelected,
												})}
												selected={isSelected}
												outerCircleAnimationProps={outerAnimationProps}
												dotAnimationProps={dotAnimationProps}
											/>
										</motion.em>
									</React.Fragment>
									<motion.span
										className={cn({
											flex: !enborderDescription,
											"w-full items-center rounded-r": true,
											[labelSize]: true,
											[`group-focus:outline-brand-${brand}`]: true,
											[textColor]: true,
											[backgroundColor]: true,
										})}
									>
										<motion.div className={labelClassname}>{label}</motion.div>
										{description && enborderDescription && (
											<motion.div
												className={cn({
													"border-t pt-2": true,
													[`${ZD_INPUT.TEXT} mt-0.5 text-xs`]:
														!isSelected || isSelected,
												})}
											>
												{description}
											</motion.div>
										)}
										{appendixNode}
									</motion.span>
								</motion.label>
							</motion.div>
							{description && !enborderDescription && (
								<motion.div
									className={cn({
										[`${ZD_INPUT.TEXT} mt-0.5 text-xs`]:
											!isSelected || isSelected,
										[descriptionClassName]: !!descriptionClassName,
									})}
								>
									{description}
								</motion.div>
							)}
						</>
					)}
				</TriggerAnimation>
			)}
		</TriggerAnimation>
	);
};
export const RvfRadioPill = ({
	name,
	isSelected,
	form,
	value,
	description,
	label,
	textColor = ZD_INPUT.TEXT,
	backgroundColor = ZD_INPUT.BACKGROUND,
	isErrored = false,
	testId = undefined,
	dataExtra = undefined,
	enborderDescription = false,
	appendixNode = <div />,
	labelSize = "text-base pl-2 pr-2 pt-2.5 pb-2.5",
	radioButtonSize = "h-6 w-6",
	minHeight = "min-h-10",
	className = "",
	labelClassname = "",
	descriptionClassName = "",
	pillStateForGroup = undefined,
	onBlur = undefined,
}: {
	name: string;
	isSelected: boolean;
	value: string;
	afterChange?: Function;
	description: ReactNode;
	appendixNode?: ReactNode;
	label: ReactNode;
	textColor?: string;
	backgroundColor?: string;
	isErrored?: boolean;
	testId?: string;
	form: FormApi<any>;
	dataExtra?: string;
	labelSize?: string;
	radioButtonSize?: string;
	descriptionClassName?: string;
	labelClassname?: string;
	minHeight?: string;
	className?: string;
	onBlur?: ((...args: any[]) => void) | undefined;
	enborderDescription?: boolean;
	pillStateForGroup?: string;
}) => {
	const { setValue } = form;

	const pillRef = useRef(null);
	const dataTestId = testId || `${name}:${value}`;
	const { brand } = useBrand();
	const commonInputProps = form.getInputProps(name, {
		onBlur: onBlur,
	});

	return (
		<TriggerAnimation
			getVariant={({ defaultTriggeredVariant }) => {
				return {
					scale: [0.8, 0.8, 1.5, 1],
					transition: {
						...defaultTriggeredVariant.transition,
						duration: 0.4,
					},
				};
			}}
		>
			{({
				triggerAnimation: outerTriggerAnimation,
				animationProps: outerAnimationProps,
				ref: ref1,
			}) => (
				<TriggerAnimation
					getVariant={({ defaultTriggeredVariant }) => {
						return {
							scale: [1, 1.3, 0.8, 1],
							transition: {
								...defaultTriggeredVariant.transition,
								duration: 0.4,
								delay: 0.1,
							},
						};
					}}
				>
					{({
						triggerAnimation: dotTriggerAnimation,
						animationProps: dotAnimationProps,
						ref: ref2,
					}) => (
						<>
							<motion.div
								ref={mergeRefs([ref1, ref2])}
								data-value-id={dataTestId}
								className={cn({
									"group flex cursor-pointer items-center rounded border-2": true,
									"transition duration-300 ease-in-out": true,
									"w-full": false,
									[ZD_INPUT.BORDER]: true,
									[`border-accent-${brand}/75`]: isSelected,
									"flex-wrap": description,
									[`focus-within:outline-none focus-within:ring-2 focus-within:ring-brandDark-${brand} focus-within:border-transparent `]: true,
									"opacity-50": pillStateForGroup === "SELECTION__UNSELECTED",
									[ZD_ERRORS.BORDER]: isErrored,
									[className]: !!className,
								})}
								data-testid={dataTestId}
								data-value={value}
								data-role={"radiobutton"}
								data-extra={dataExtra}
							>
								<motion.label
									className={cn({
										"group flex h-full w-full cursor-pointer": true,
										[`group-focus:outline-brand-${brand}`]: true,
									})}
								>
									<React.Fragment>
										<input
											className={cn({
												"form-radio pin-t pin-l pointer-events-none absolute border-0 text-base opacity-0": true, // Pointer-Events are not on b/c SelectedRadioButtonField has issues with clicking on the dot
												[ZD_ERRORS.BORDER]: isErrored,
												[`group-focus:outline-brand-${brand}`]: true,
											})}
											type={"radio"}
											key={value}
											checked={isSelected}
											id={`${name}:${value}`}
											data-testid={name}
											ref={mergeRefs([pillRef, commonInputProps.ref])}
											name={commonInputProps.name}
											onBlur={commonInputProps.onBlur}
											onChange={(event) => {
												dotTriggerAnimation();
												outerTriggerAnimation();
												commonInputProps.onChange?.(event);
											}}
											value={value}
										/>
										<motion.em
											className={cn({
												"flex items-center justify-center rounded-l border-r bg-white px-2": true,
												[ZD_INPUT.BORDER]: !isSelected,
												[minHeight]: true,
												[`border-accent-${brand}/50`]: isSelected,
												[`group-focus:outline-brand-${brand}`]: true,
											})}
										>
											<CircleIcon
												className={cn({
													[radioButtonSize]: true,
													"text-white": !isSelected,
													[`text-accent-${brand}/75`]: isSelected,
												})}
												selected={isSelected}
												outerCircleAnimationProps={outerAnimationProps}
												dotAnimationProps={dotAnimationProps}
											/>
										</motion.em>
									</React.Fragment>
									<motion.span
										className={cn({
											flex: !enborderDescription,
											"w-full items-center rounded-r": true,
											[labelSize]: true,
											[`group-focus:outline-brand-${brand}`]: true,
											[textColor]: true,
											[backgroundColor]: true,
										})}
									>
										<motion.div className={labelClassname}>{label}</motion.div>
										{description && enborderDescription && (
											<motion.div
												className={cn({
													"border-t pt-2": true,
													[`${ZD_INPUT.TEXT} mt-0.5 text-xs`]:
														!isSelected || isSelected,
												})}
											>
												{description}
											</motion.div>
										)}
										{appendixNode}
									</motion.span>
								</motion.label>
							</motion.div>
							{description && !enborderDescription && (
								<motion.div
									className={cn({
										[`${ZD_INPUT.TEXT} mt-0.5 text-xs`]:
											!isSelected || isSelected,
										[descriptionClassName]: !!descriptionClassName,
									})}
								>
									{description}
								</motion.div>
							)}
						</>
					)}
				</TriggerAnimation>
			)}
		</TriggerAnimation>
	);
};
