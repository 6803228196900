import type { FormApi } from "@rvf/remix";
import type { ReactNode } from "react";
import type React from "react";
import { useEffect, useMemo } from "react";
import { useControlField, useField } from "remix-validated-form";

import { cn } from "@/lib/utils.ts";
import { ZD_ERRORS, ZD_INPUT } from "~zipdeal-ui/globalColors.ts";
import { useValidatorField } from "~zipdeal-ui/hooks/useValidatorField.tsx";
import { ErrorMessage } from "~zipdeal-ui/inputs/common/ErrorMessage.tsx";
import { useZipTipStore } from "~zipdeal-ui/stores/zipTipsStore.ts";
import { trueFalseData } from "~zipdeal-ui/utilities/stables.ts";
import { RadioPill, RvfRadioPill } from "./components/RadioPill.tsx";
import { ZipTip } from "./components/ZipTip.tsx";

interface ZipRadioButtonsInputProps {
	name: string;
	data: any[];
	label?: ReactNode;
	description?: ReactNode;
	ref?: React.Ref<HTMLDivElement>;
	className?: string;
	textSize?: string;
	wrapperClassName?: string;
	descriptionClassName?: string;
	options?: Record<string, any>;
	textField?: Function | string;
	valueField?: Function | string;
	selectionIndicationFn?: (radioButtonValue, selectedGroupValue) => boolean;
	afterChange?: Function;
	wider?: boolean;
	trueFalse?: boolean;
	textColor?: string;
	backgroundColor?: string;
	pillClassName?: string;
	zipTipTools?: {
		zipTip?: string | null;
		loadZipTip?: (name: string) => void;
	};
	pillDescriptionClassName?: string;
	formId: string;
	width?: string;
	margin?: string;
	PillComponent?: any;
	ordinalTestIds?: boolean;
	errorPreserveSpace?: boolean;
	value?: any;
	defaultValue?: any;
	inError?: boolean;
	errorElement?: ReactNode;
	translation?: Function;
	onChange?: Function;
	labelSize?: string;
	radioButtonSize?: string;
	labelClassname?: string;
	minHeight?: string;
	enborderDescription?: boolean;
	appendixFunc?: Function;
	onBlur?: ((...args: any[]) => void) | undefined;
	onDoubleClick?: Function;
}

export const ZipRadioButtonsInput = ({
	name,
	data,
	formId,
	label = undefined,
	description = null,
	className = "",
	wrapperClassName = "",
	descriptionClassName = "",
	pillClassName = "",
	pillDescriptionClassName = "",
	textSize = "text-base",
	options = {},
	textField = undefined,
	valueField = undefined,
	selectionIndicationFn = (radioButtonValue, selectedGroupValue) =>
		radioButtonValue === selectedGroupValue,
	wider = false,
	trueFalse = false,
	zipTipTools = undefined,
	textColor = ZD_INPUT.TEXT,
	backgroundColor = ZD_INPUT.BACKGROUND,
	PillComponent = RadioPill,
	ordinalTestIds = false,
	value = undefined,
	defaultValue = undefined,
	afterChange = undefined,
	width = "w-full",
	margin = "mt-2",
	errorPreserveSpace = false,
	inError = false,
	errorElement = undefined,
	translation = undefined,
	onChange = undefined,
	labelSize = undefined,
	radioButtonSize = undefined,
	minHeight = undefined,
	enborderDescription = false,
	appendixFunc = undefined,
	onDoubleClick = undefined,
	labelClassname = "",
	onBlur = undefined,
	ref = undefined,
}: ZipRadioButtonsInputProps) => {
	const { error, getInputProps } = useField(name, {
		formId: formId || "undetermined-form",
	});
	const [_value, _setValue] = useControlField<string>(name, formId);

	const _inError = inError || !!error;
	const _errorElement = errorElement || error;

	const {
		setValidatorValue,
		error: errorValidation,
		validatedInputEl,
		validatorValidate,
		isFormInitialized,
	} = useValidatorField<string>(name, formId);

	useEffect(() => {
		if (value !== undefined) {
			_setValue(value);
			setValidatorValue?.(value);
			afterChange?.(value);
		}
	}, [_setValue, afterChange, setValidatorValue, value]);

	useEffect(() => {
		setValidatorValue?.(_value);
		isFormInitialized && validatorValidate?.();
	}, [_value, isFormInitialized, setValidatorValue, validatorValidate]);

	const radios = data
		?.filter((i) => i)
		.map((val, n) => {
			let label;
			let appendixNode;
			if (typeof textField === "function") label = textField(val);
			else label = textField ? val[textField] : val;

			let radioButtonValue;
			if (typeof valueField === "function") radioButtonValue = valueField(val);
			else radioButtonValue = valueField ? val[valueField] : val;
			const description = val.description;

			const isSelected = selectionIndicationFn(radioButtonValue, _value);

			const pillStateForGroup =
				!isSelected && _value ? "SELECTION__UNSELECTED" : undefined;

			const dataExtra = val["data-extra"];

			if (appendixFunc) appendixNode = appendixFunc(val);

			return (
				<PillComponent
					key={`${val}-${n}`}
					formId={formId}
					name={name}
					label={label}
					textColor={textColor}
					backgroundColor={backgroundColor}
					description={description}
					isSelected={isSelected}
					trueFalse={trueFalse}
					value={radioButtonValue?.toString()}
					isErrored={_inError}
					afterChange={afterChange}
					setValidatorValue={setValidatorValue}
					testId={ordinalTestIds ? `${name}:${n}` : undefined}
					dataExtra={dataExtra}
					onChange={onChange}
					labelSize={labelSize}
					radioButtonSize={radioButtonSize}
					minHeight={minHeight}
					appendixNode={appendixNode}
					enborderDescription={enborderDescription}
					onDoubleClick={onDoubleClick}
					className={pillClassName}
					descriptionClassName={pillDescriptionClassName}
					pillStateForGroup={pillStateForGroup}
					labelClassname={labelClassname}
					onBlur={onBlur}
				/>
			);
		});

	const zipTips = useZipTipStore((state) => state.zipTips);
	const hasZipTip = useMemo(() => {
		return zipTips?.some((t) => t.field === name);
	}, [zipTips, name]);

	return (
		<div
			ref={ref}
			data-role={"radiobutton-group"}
			className={cn({
				relative: true,
				[width]: true,
				disabled: options?.disabled,
				[wrapperClassName]: true,
			})}
			{...options}
		>
			{label && (
				<label
					className={cn({
						[textSize]: true,
						"font-regular flex gap-x-1": true,
						"tracking-wide": !wider,
						"tracking-wider": wider,
						[ZD_INPUT.LABEL]: !_inError,
						[ZD_ERRORS.LABEL]: _inError,
					})}
					htmlFor=""
				>
					{label}
					<ZipTip
						hasZipTip={hasZipTip}
						loadZipTip={() => zipTipTools?.loadZipTip?.(name)}
						zipTip={zipTipTools?.zipTip}
					/>
				</label>
			)}
			{description && (
				<div
					className={cn({
						"block text-sm font-thin": true,
						"tracking-wide": !wider,
						"tracking-wider": wider,
						[ZD_INPUT.TEXT]: !_inError,
						[ZD_ERRORS.TEXT]: _inError,
						[descriptionClassName]: !!descriptionClassName,
					})}
				>
					{description}
				</div>
			)}
			<div
				className={cn({
					[margin]: !!margin,
					"flex-wrap": true,
					[className]: true,
				})}
				data-testid={`${name}`}
			>
				{radios}
			</div>
			{validatedInputEl}
			<ErrorMessage
				elementInError={_inError}
				translation={translation}
				erroredElementMsg={_errorElement}
				preserveSpace={errorPreserveSpace}
			/>
		</div>
	);
};

export const ZipTrueFalseRadioButtonsInput = ({
	label,
	name,
	formId,
	className,
	value = undefined,
	description = undefined,
	width = undefined,
	translation = undefined,
}: {
	label: string;
	name: string;
	className: string;
	value?;
	formId: string;
	translation?: Function;
	description?: string;
	width?: string;
}) => (
	<ZipRadioButtonsInput
		label={label}
		value={value}
		trueFalse={true}
		textField={"label"}
		valueField={"value"}
		translation={translation}
		description={description}
		data={trueFalseData}
		className={className}
		name={name}
		width={width}
		formId={formId}
	/>
);

export const ZipRvfRadioButtonsInput = ({
	name,
	data,
	form,
	label = undefined,
	description = null,
	className = "",
	wrapperClassName = "",
	descriptionClassName = "",
	pillClassName = "",
	pillDescriptionClassName = "",
	textSize = "text-base",
	options = {},
	textField = undefined,
	valueField = undefined,
	selectionIndicationFn = (radioButtonValue, selectedGroupValue) =>
		radioButtonValue === selectedGroupValue,
	wider = false,
	trueFalse = false,
	zipTipTools = undefined,
	textColor = ZD_INPUT.TEXT,
	backgroundColor = ZD_INPUT.BACKGROUND,
	ordinalTestIds = false,
	value = undefined,
	afterChange = undefined,
	width = "w-full",
	margin = "mt-2",
	errorPreserveSpace = false,
	inError = false,
	defaultValue = undefined,
	errorElement = undefined,
	translation = undefined,
	onChange = undefined,
	labelSize = undefined,
	radioButtonSize = undefined,
	minHeight = undefined,
	enborderDescription = false,
	appendixFunc = undefined,
	onDoubleClick = undefined,
	labelClassname = "",
	onBlur = undefined,
	ref = undefined,
}: {
	name: string;
	data: any[];
	label?: ReactNode;
	description?: ReactNode;
	ref?: React.Ref<HTMLDivElement>;
	className?: string;
	textSize?: string;
	wrapperClassName?: string;
	descriptionClassName?: string;
	options?: Record<string, any>;
	textField?: Function | string;
	valueField?: Function | string;
	selectionIndicationFn?: (radioButtonValue, selectedGroupValue) => boolean;
	afterChange?: Function;
	wider?: boolean;
	trueFalse?: boolean;
	textColor?: string;
	backgroundColor?: string;
	pillClassName?: string;
	zipTipTools?: {
		zipTip?: string | null;
		loadZipTip?: (name: string) => void;
	};
	pillDescriptionClassName?: string;
	form: FormApi<any>;
	width?: string;
	defaultValue?: string;
	margin?: string;
	ordinalTestIds?: boolean;
	errorPreserveSpace?: boolean;
	value?: any;
	inError?: boolean;
	errorElement?: ReactNode;
	translation?: Function;
	onChange?: Function;
	labelSize?: string;
	radioButtonSize?: string;
	labelClassname?: string;
	minHeight?: string;
	enborderDescription?: boolean;
	appendixFunc?: Function;
	onBlur?: ((...args: any[]) => void) | undefined;
	onDoubleClick?: Function;
}) => {
	const { value: getValue, setValue } = form;

	const _value = getValue(name);

	const _setValue = (v) => setValue(name, v);

	const error = false;
	const _inError = inError || !!error;
	const _errorElement = errorElement || error;

	useEffect(() => {
		if (value !== undefined) {
			_setValue(value);
			afterChange?.(value);
		}
	}, [_setValue, afterChange, value]);

	// useEffect(() => {
	// 	if (defaultValue !== undefined && value === undefined) {
	// 		_setValue(defaultValue);
	// 		afterChange?.(defaultValue);
	// 	}
	// }, [_setValue, afterChange, defaultValue, value]);

	const radios = data
		?.filter((i) => i)
		.map((val, n) => {
			let label;
			let appendixNode;
			if (typeof textField === "function") label = textField(val);
			else label = textField ? val[textField] : val;

			let radioButtonValue;
			if (typeof valueField === "function") radioButtonValue = valueField(val);
			else radioButtonValue = valueField ? val[valueField] : val;
			const description = val.description;

			const isSelected = selectionIndicationFn(radioButtonValue, _value);

			const pillStateForGroup =
				!isSelected && _value ? "SELECTION__UNSELECTED" : undefined;

			const dataExtra = val["data-extra"];

			if (appendixFunc) appendixNode = appendixFunc(val);

			return (
				<RvfRadioPill
					key={`${val}-${n}`}
					form={form}
					name={name}
					label={label}
					textColor={textColor}
					backgroundColor={backgroundColor}
					description={description}
					isSelected={isSelected}
					value={radioButtonValue?.toString()}
					isErrored={_inError}
					afterChange={afterChange}
					testId={ordinalTestIds ? `${name}:${n}` : undefined}
					dataExtra={dataExtra}
					labelSize={labelSize}
					radioButtonSize={radioButtonSize}
					minHeight={minHeight}
					appendixNode={appendixNode}
					enborderDescription={enborderDescription}
					className={pillClassName}
					descriptionClassName={pillDescriptionClassName}
					pillStateForGroup={pillStateForGroup}
					labelClassname={labelClassname}
					onBlur={onBlur}
				/>
			);
		});

	const zipTips = useZipTipStore((state) => state.zipTips);
	const hasZipTip = zipTips?.some((t) => t.field === name);
	return (
		<div
			ref={ref}
			data-role={"radiobutton-group"}
			className={cn({
				relative: true,
				[width]: true,
				disabled: options?.disabled,
				[wrapperClassName]: true,
			})}
			{...options}
		>
			{label && (
				<label
					className={cn({
						[textSize]: true,
						"font-regular flex gap-x-1": true,
						"tracking-wide": !wider,
						"tracking-wider": wider,
						[ZD_INPUT.LABEL]: !_inError,
						[ZD_ERRORS.LABEL]: _inError,
					})}
					htmlFor=""
				>
					{label}
					<ZipTip
						hasZipTip={hasZipTip}
						loadZipTip={() => zipTipTools?.loadZipTip?.(name)}
						zipTip={zipTipTools?.zipTip}
					/>
				</label>
			)}
			{description && (
				<div
					className={cn({
						"block text-sm font-thin": true,
						"tracking-wide": !wider,
						"tracking-wider": wider,
						[ZD_INPUT.TEXT]: !_inError,
						[ZD_ERRORS.TEXT]: _inError,
						[descriptionClassName]: !!descriptionClassName,
					})}
				>
					{description}
				</div>
			)}
			<div
				className={cn({
					[margin]: !!margin,
					"flex-wrap": true,
					[className]: true,
				})}
				data-testid={`${name}`}
			>
				{radios}
			</div>
			<ErrorMessage
				elementInError={_inError}
				translation={translation}
				erroredElementMsg={_errorElement}
				preserveSpace={errorPreserveSpace}
			/>
		</div>
	);
};
