import { cn } from "@/lib/utils.ts";
import { motion } from "framer-motion";
import React from "react";

export const CircleIcon = React.memo(
	({
		className = "",
		selected = false,
		outerCircleAnimationProps = {},
		dotAnimationProps = {},
	}: {
		className?: string;
		selected?: boolean;
		outerCircleAnimationProps?: any;
		dotAnimationProps?: any;
	}) => {
		return (
			<motion.svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 32 32"
				className={cn({
					["transition duration-100 ease-in-out"]: true,
					[className]: !!className,
				})}
			>
				<motion.circle
					className={cn({
						["fill-current stroke-zd-gray-500/50 stroke-2"]: !selected,
						["fill-white stroke-current stroke-2"]: selected,
					})}
					cx={"16"}
					cy={"16"}
					r={selected ? "10" : "11"}
					{...outerCircleAnimationProps}
				/>
				{selected && (
					<motion.circle
						className={cn({
							["fill-current stroke-none"]: selected,
						})}
						{...dotAnimationProps}
						cx={"16"}
						cy={"16"}
						r={6.5}
					/>
				)}
			</motion.svg>
		);
	},
);
